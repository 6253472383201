@media only screen and (min-width: 1792px) {
  body {
    min-width: 1792px;
  }

  .page .container {
    padding: 0 170px 0 170px;
  }

  .banner {
    .banner-logo {
      margin-top: 50px;
      width: 592px;
      height: 262px;
    }
    .banner-man {
      width: 649px;
      height: 708px;
    }

    .join-waitlist {
      @apply absolute inline-block;

      bottom: 54px;
      right: 45px;
      width: 348px;
      height: 133px;
    }
  }

  .html-embed {
    min-height: 600px;
  }

  #home-top {
    padding-top: 88px;
    padding-bottom: 326px;
    h1 {
      @apply text-22.5 leading-12 mt-20;
      letter-spacing: 0.23em;
    }
    p {
      @apply font-bogart text-6.25 leading-7.5 text-center;
    }
  }

  #crypto-court {
    min-height: 1000px;
    h1 {
      @apply text-15 leading-12.5;
    }

    p {
      @apply leading-4 text-center;
    }

    .crypto-court-content {
      @apply ml-0;
      top: 30%;
      left: 9.5%;
      width: 42%;
    }
  }

  #crypto-court2-horse-wrap {
    #crypto-court2-horse {
      width: 1150px;
      height: 554px;
    }
  }

  #token {
    .container2 {
      max-width: 1153px;
    }

    margin-top: -30px;

    background: url('../../assets/images/judge-desktop/crypto-court2-bg.png') 0 0 no-repeat;
    min-height: 769px;

    &.preloading-bg {
      background: url('../../assets/images/judge-desktop/token-bg.png') 0 0 no-repeat;
    }

    #solana-logo {
      width: 1360px;
      padding-top: 240px;
    }

    &.token-anim1 {
      #solana-logo {
        padding-top: 100px;
      }
    }

    .token-content-wrap {
      margin: 500px 500px;
    }
    &.token-anim2 {
      background: url('../../assets/images/judge-desktop/token-bg.png') 0 0 no-repeat;
      background-size: cover;
      -webkit-animation: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      height: 1608px;
    }

    h1.token-title {
      @apply text-15 leading-17.5 mt-13 mb-15;
      max-width: 40%;
    }

    h3.token-subtitle {
      @apply text-10 leading-13.25 mt-10;
    }

    p.token-content {
      @apply text-5.75 leading-8;
      letter-spacing: 0em;
    }

    p.note {
      @apply text-5 leading-7.5 font-bogart text-brown2 py-8;
    }

    ul.voting-list {
      li {
        @apply text-5.75 leading-9;
        &:last-child {
          @apply mt-0;
        }
      }
    }

    .token-img1,
    .token-img2 {
      @apply absolute bottom-2;
    }

    .token-img1 {
      width: 299px;
      height: 598px;
    }
    .token-img2 {
      width: 257px;
      height: 601px;
    }
  }

  #token-bottom {
    @apply relative z-10;
    margin-top: -160px;
  }

  #token-bottom2 {
    @apply relative z-20;
    margin-top: -69px;
  }

  #tax {
    margin-top: -225px;
    padding-bottom: 187px;
    padding-left: 7%;

    #tax1 {
      padding-top: 225px;

      .tax1-img {
        width: 1259px;
        height: 856px;
      }

      .tax1-star1 {
        @apply -left-12 -top-5;
      }

      .tax1-star-single {
        width: 71px;
        height: 101px;
        margin-top: 160px;
        margin-left: 150px;
      }

      .tax1-star2 {
        margin-top: 330px;
        margin-left: 180px;
      }

      .tax1-pistol {
        width: 332px;
        height: 221px;
        left: 80px;
        top: 140px;
      }

      .tax1-content {
        top: 280px;
        left: 260px;
        max-width: 790px;

        h1 {
          margin-bottom: 40px;
        }
      }
    }

    #tax2 {
      padding-top: 80px;

      .house {
        width: 545px;
        height: 545px;
      }

      .tax2-img {
        width: 1063px;
        height: 856px;
      }

      .gun {
        width: 450px;
        height: 270px;
        top: -84px;
        right: -200px;
      }

      .tax2-content {
        width: 834px;
        left: 52%;
        margin-top: 100px;
        margin-left: -417px;
        h1 {
          margin-bottom: 40px;
          letter-spacing: 0;
        }
      }
    }

    .wanted-bottom {
      @apply z-10;
      bottom: -140px;
      left: 80px;
      width: 332px;
      height: 524px;
    }
  }

  #tokenomics {
    min-height: 1053px;

    .inner {
      padding: 160px 6.64% 270px 6.64%;
    }

    .tokenomics-left {
      @apply pt-52;

      h1 {
        @apply text-15 leading-17.5 mb-8;
        letter-spacing: 0.15em;
      }
      p {
        @apply text-5.75 leading-8;
      }
    }

    .tokenomics-right {
      @apply ml-20;

      .tokenomics-table {
        padding: 100px 40px;

        p {
          @apply text-5.75 leading-8;
          max-width: 663px;
        }

        table {
          margin-top: 60px;
        }

        td.cell-label {
          font-size: 33px;
        }

        td.cell-value {
          font-size: 25px;
          line-height: 40px;
        }
      }
    }
  }

  #official {
    min-height: 1039px;

    .official-inner {
      padding: 175px 127px 175px 127px;

      h1.official-title {
        font-size: 90px;
        line-height: 50px;
      }
    }
  }

  #nft {
    min-height: 717px;

    .nft-content-wrap {
      bottom: 20px;
      width: 1139px;
      height: 353px;
      left: 50%;
      margin-left: -568px;
      padding: 70px 80px;

      h1 {
        font-size: 62px;
        line-height: 50px;
      }

      p.nft-content {
        letter-spacing: -0.05em;
      }
    }
  }

  #join {
    min-height: 848px;

    .join-link {
      top: 42%;
      left: 30%;
      width: 1092px;
      height: 203px;
      text-indent: -9999px;
    }
  }
}
