.html-embed {
  video {
    height: 100vh;
  }
}

.banner {
  display: block;
  position: relative;

  .join-waitlist {
    @apply absolute inline-block;
    text-indent: -9999px;
    overflow: hidden;

    bottom: 89px;
    right: 45px;
    width: 188px;
    height: 78px;
  }
}

.bg-wrap {
  @apply -mx-6;
}

#judgement {
  h1 {
    --stroke-color: rgba(97, 84, 66, 1);
    --stroke-width: 2px;

    text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color);
  }
}
#crypto-court {
  h1 {
    @apply text-center text-6 font-bon text-white leading-12.5;

    --stroke-color: rgba(33, 28, 25, 1);
    --stroke-width: 4px;

    text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color);
  }

  p {
    @apply leading-4 text-center;
  }

  .crypto-court-content {
    bottom: 13%;
    left: 50%;
    margin-left: -35%;
    width: 70%;
  }
}

.crypto-court-bottom {
  @apply relative;
  margin-top: -30px;
}

#token {
  @apply relative text-center font-bon;

  margin-top: -30px;

  background: url('../../assets/images/judge/token-bg.png') 0 0 no-repeat;
  background-size: cover;
  background-clip: content-box;

  #solana-logo {
    width: 152px;
    padding-top: 70px;
    @apply mx-auto;
  }

  h1.token-title {
    @apply text-7.5 leading-7.5 text-white mx-auto mt-4;
    max-width: 300px;
  }

  h3.token-subtitle {
    @apply font-bon text-5 leading-9 mt-10 text-brown2;
  }

  p.token-content {
    @apply font-bogart text-3 leading-5  text-white;

    &.voting-fee {
      @apply tracking-tighter;
    }
    &.weekly {
      @apply leading-9 tracking-tighter;
    }
  }

  ul.voting-list {
    @apply list-disc list-inside;

    li {
      @apply font-bogart text-3 leading-5 text-white;
      &:last-child {
        @apply mt-4;
      }
    }
  }
}

#tax {
  @apply relative text-center font-bon;

  background: url('../../assets/images/judge/tax-bg.png') 0 0 no-repeat;
  background-size: cover;
  background-clip: content-box;

  h1 {
    color: rgba(224, 193, 149, 1);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(33, 28, 25, 1);
  }

  #tax1-img {
    padding-top: 160px;
  }

  .tax1-content {
    @apply w-full;
    top: 370px;
  }

  .tax2-content {
    @apply w-full;
    top: 150px;
  }
}

#tokenomics {
  @apply relative text-center font-bon;

  background: url('../../assets/images/judge/tokenomics-bg.png') 0 0 no-repeat;
  background-size: cover;
  background-clip: content-box;

  .tokenomics-title-wrap {
    h1 {
      @apply font-bon text-7.5 leading-10 text-white;
      letter-spacing: 0.15em;
      max-width: 190px;
    }
  }

  .tokenomics-table-wrap {
    @apply relative;

    h1 {
      @apply text-brown2;
    }

    .tokenomics-table {
      @apply absolute px-6 w-full;
      top: 40px;

      table {
        @apply mx-auto w-64 mt-4;

        td.cell-label {
          @apply text-brown2 text-4.5  leading-8 text-left;
        }
        td.cell-value {
          @apply text-white text-right text-4 leading-8;
        }

        tr.total td {
          @apply underline;
        }
      }
    }
  }
}

#official {
  @apply relative text-center font-bon;

  background: url('../../assets/images/judge/official-bg.png') 0 0 no-repeat;
  background-size: cover;
  background-clip: content-box;

  height: 649px;

  h1.official-title {
    @apply pt-40 text-brown2;
  }
}

#nft {
  @apply relative text-center font-bon;

  .nft-content-wrap {
    @apply absolute;
    bottom: 70px;
    width: 320px;
    left: 50%;
    margin-left: -160px;

    p.nft-content {
      @apply font-bogart mx-auto text-3 px-1 leading-4;
      color: rgba(33, 28, 25, 1);
      letter-spacing: -1.5px;
    }
  }
}

#crypto-holder {
  @apply relative text-center font-bon;

  background: url('../../assets/images/judge/crypto-holder-bg.png') 0 0 no-repeat;
  background-size: cover;
  background-clip: content-box;

  height: 795px;

  .crypto-holder-content-wrap {
    @apply absolute;
    bottom: 80px;
    width: 80%;
    left: 50%;
    margin-left: -40%;

    p.crypto-holder-content1,
    p.crypto-holder-content2 {
      @apply font-bogart mx-auto;
      max-width: 325px;
    }

    p.crypto-holder-content1 {
      @apply leading-5 text-3.25 text-white;
      letter-spacing: -1.5px;
    }

    p.crypto-holder-content2 {
      @apply leading-5 text-3.25 mt-10;
      color: rgba(224, 193, 149, 1);
    }
  }
}

#join {
  .join-link {
    @apply absolute;

    top: 42%;
    left: 30%;
    width: 60%;
    height: 20%;
    text-indent: -9999px;
    overflow: hidden;
  }
}

/**
=============================================================    425px    =========================================================== */
@media only screen and (min-width: 425px) {
  body {
    font-size: 102%;
  }

  #token {
    margin-top: -30px;

    #solana-logo {
      width: 152px;
      padding-top: 70px;
    }

    h1.token-title {
      max-width: 300px;
    }

    p.token-content {
      &.voting-fee {
        @apply tracking-normal;
      }
      &.weekly {
        @apply tracking-normal;
      }
    }
  }

  #tax {
    #tax1-img {
      padding-top: 160px;
    }

    .tax1-content {
      top: 370px;
    }

    .tax2-content {
      top: 150px;
    }
  }

  #tokenomics {
    .tokenomics-table-wrap {
      .tokenomics-table {
        top: 9%;

        table {
          @apply mx-auto w-64 mt-4;

          td.cell-label {
            @apply leading-9;
          }
          td.cell-value {
            @apply leading-9;
          }

          tr.total td {
            @apply underline;
          }
        }
      }
    }
  }

  #official {
    @apply relative text-center font-bon;

    height: 649px;

    h1.official-title {
      @apply pt-40 text-brown2;
    }
  }

  #nft {
    .nft-content-wrap {
      bottom: 15%;
      width: 80%;
      margin-left: -40%;
    }
  }

  #crypto-holder {
    .crypto-holder-content-wrap {
      p.crypto-holder-content1,
      p.crypto-holder-content2 {
        max-width: 325px;
      }

      p.crypto-holder-content1 {
        letter-spacing: normal;
      }
    }
  }

  #join {
    .join-link {
      top: 42%;
      left: 30%;
      width: 60%;
      height: 20%;
    }
  }
}
