/** Form
------------------------------------------------------- */
.help-block {
  display: block;
  font-family: bon;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #666666;
  margin-bottom: 1rem;

  &.errors {
    margin-top: 1rem;
    color: #cc2200;
  }
}

.form {
  margin-top: 4rem;
  font-family: Suisse;

  .form-col-1 {
  }

  .form-row {
    margin-bottom: 2rem;

    .form-label {
      margin-bottom: 0.5rem;
      min-width: 10rem;
      margin-right: 2rem;
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1rem;
      color: #fff;

      &.required:after {
        content: '*';
        color: #d11149;
        margin-left: 5px;
      }
    }

    input,
    select,
    textarea {
      border-radius: 0.5rem;
      padding: 1rem 0.25rem 1rem 1.25rem;
      border: 1.5px solid #e6e6e6;
      outline: none;
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      line-height: 1rem;
      width: 100%;
      color: #ffffff;
      background: none;

      &:hover,
      &:active,
      &:focus {
        border-color: #a6a6a6;
      }
    }

    &.form-row-inline {
      .form-label {
        margin-bottom: 0;
        min-width: auto;
      }

      & input,
      & select,
      & textarea {
        width: unset;
      }
    }

    select {
      padding: 0.875rem 1.25rem;

      & option {
        color: #222222;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .form {
    margin-top: 2rem;

    .form-row {
      margin-bottom: 1rem;
    }
  }
}
