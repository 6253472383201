/**
=============================================================    480px    =========================================================== */
@media only screen and (min-width: 480px) {
  body {
    font-size: 102%;
  }

  #crypto-court {
    p {
      @apply leading-6;
    }
  }

  #token {
    #solana-logo {
      width: 162px;
      @apply pt-20 pb-5;
    }

    h1.token-title {
      max-width: 400px;
    }
  }

  #tax {
    .tax1-content {
      top: 45%;
      & > div {
        @apply ssm:max-w-80;
      }
    }

    .tax2-content {
      top: 20%;

      & > div {
        @apply ssm:max-w-80;
      }
    }
  }

  #tokenomics {
    .tokenomics-table-wrap {
      .tokenomics-table {
        top: 12%;

        table {
          @apply leading-10;
        }
      }
    }
  }

  #nft {
    .nft-content-wrap {
      bottom: 18%;
      width: 70%;
      margin-left: -35%;
      p {
        @apply leading-8;
      }
    }
  }

  #crypto-holder {
    height: 900px;

    .crypto-holder-content-wrap {
      p.crypto-holder-content1,
      p.crypto-holder-content2 {
        max-width: 325px;
      }

      p.crypto-holder-content1 {
        letter-spacing: normal;
      }
    }
  }

  #join {
    .join-link {
      top: 42%;
      left: 30%;
      width: 60%;
      height: 20%;
    }
  }
}
