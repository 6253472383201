@font-face {
  font-family: 'bon';
  font-display: swap;
  src: url('assets/fonts/boncegro-ff-4f.regular.ttf') format('truetype');
}

@font-face {
  font-family: 'bogart';
  font-display: swap;
  src: url('assets/fonts/BOGARTREGULARTRIAL.TTF') format('truetype');
}
/* 
@font-face {
  font-family: 'bogart';
  font-display: swap;
  src: url('assets/fonts/Bogart-Regular-trial.ttf') format('truetype');
}
 */
@font-face {
  font-family: 'brawler';
  font-display: swap;
  src: url('assets/fonts/Brawler-Regular.ttf') format('truetype');
}

html {
  font-size: 1rem;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  text-size-adjust: 100%;
}

body {
  font-family: bog;
  font-size: 0.75rem;
  line-height: 1.25rem;
  background-color: #000;
  overflow-x: hidden;

  @apply text-white2;

  &.modal-active {
    height: 100vh;
    /* overflow-y: hidden; */
  }
}

/** Typography
-------------------------------------------------------  */
h1 {
  &.base {
    //
  }
}

/** Typography
-------------------------------------------------------  */
h1 {
  &.base {
    //
  }
}

h2 {
  @apply leading-8.5 font-bon;
  &.rotated {
    @apply text-7.5;
    max-width: 98px;
    transform: rotate(-19.85deg);
  }
}

h3 {
  @apply font-bon leading-5;

  &.base {
    @apply text-5;
  }
}

h4 {
  @apply font-bon leading-9;

  &.base {
    @apply text-4.5;
  }
}

p {
}

/** Layout
-------------------------------------------------------  */
.page {
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  .container {
    @apply mx-auto px-6 sm:px-8;
  }
}

.logo-wrap {
  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    margin-left: 0.75rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
}

/** Full screen mode  */
.page.full-screen {
  #viewer-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 2;

    #viewer {
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
    }
  }

  .home-content {
    display: none;
  }
}

/** Notification message
------------------------------------------------------- */
.notification-info,
.notification-success {
  background-color: #d054d0;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

/** Responsiveness
------------------------------------------------------- */

@media only screen and (max-width: 767px) {
  .page {
  }
}

@media only screen and (max-width: 639px) {
  .page {
  }
}

@media only screen and (max-width: 375px) {
  .page {
  }
}

@media only screen and (min-width: 640px) {
}

@media only screen and (min-width: 768px) {
  h1 {
    &.base {
      @apply text-17.5 leading-18.75;
    }
  }

  h2 {
    @apply leading-8.5 font-bon;
    &.rotated {
      @apply text-7.5;
      max-width: 98px;
      transform: rotate(-19.85deg);
    }
  }

  h3 {
    @apply font-bon leading-5;

    &.base {
      @apply text-5;
    }
  }

  p {
    @apply text-5 leading-10;
  }
}

@media only screen and (min-width: 1024px) {
  h1 {
    &.base {
      @apply text-20 leading-19.5;
    }
  }
}

@media only screen and (min-width: 1280px) {
  h1 {
    &.base {
      @apply text-27 leading-23.75;
    }
  }
}

@media only screen and (min-width: 1792px) {
}
