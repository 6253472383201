a.nav {
  cursor: pointer;
}

.header-nav-wrap {
  .header-nav {
    .connect-button {
      color: #ffffff;
    }

    a.nav {
      @apply font-bon text-4.25 leading-7.5 text-white uppercase relative cursor-pointer;

      &:hover {
        visibility: visible;
        -webkit-transform: scaleX(1);
        -ms-transform: scaleX(1);
        transform: scaleX(1);

        &:before {
          visibility: visible;
          -webkit-transform: scaleX(1);
          -ms-transform: scaleX(1);
          transform: scaleX(1);
        }
      }

      &:active,
      &:focus {
        &:before {
          visibility: hidden;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 90%;
        height: 2px;
        bottom: -1rem;
        left: 0;
        margin-left: 5%;
        background-color: #ffffff;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        -ms-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }

      &.connect-button:before {
        display: none;
      }
    }

    .logo-wrap {
      @apply hidden;
    }
  }
}

.header-nav-modal {
  background-image: url('../../assets/images/judge/menu-bg-375.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  a.nav {
    @apply font-bon text-4.25 leading-7.5 text-white uppercase relative cursor-pointer my-2;
  }
}

.logo-wrap {
  img {
    width: 223px;
    height: 74px;
  }
}

@media only screen and (min-width: 769px) {
  .ham {
    padding-top: 52px;
    padding-left: 92px;
  }

  .header-nav-modal {
    @apply pb-0;
    background-image: url('../../assets/images/judge-desktop/menu-bg.png');

    .ham {
      padding-top: 22px;
    }

    a.nav {
      font-size: 45px;
      line-height: 60px;
      margin: 20px 0;
    }
  }
}
