/**
=============================================================    640px    =========================================================== */
@media only screen and (min-width: 640px) {
  #judgement,
  #crypto-court,
  #token,
  #token,
  #nft,
  #tokenomics,
  #tokenomics .tokenomics-title-wrap,
  #tax {
    h1,
    h1.token-title {
      @apply sm:text-13.25 sm:leading-17.5 md:text-15 md:leading-20;
    }
    p {
      @apply sm:text-5 sm:leading-8 md:text-6 md:leading-10;
    }
  }

  #token {
    #solana-logo {
      width: 35%;
    }

    h3.token-subtitle {
      @apply sm:text-8 sm:leading-12;
    }

    p.token-content {
      @apply sm:text-5 sm:leading-7;
    }
    ul.voting-list li {
      @apply sm:text-5 sm:leading-7;
    }
  }

  #tax {
    .tax1-content {
      top: 42%;
      & > div {
        @apply ssm:max-w-80 sm:max-w-md;
      }
    }

    .tax2-content {
      top: 20%;

      & > div {
        @apply ssm:max-w-80 sm:max-w-md;
      }
    }
  }

  #tokenomics {
    .tokenomics-title-wrap h1 {
      @apply sm:max-w-md;
    }
    .tokenomics-table-wrap {
      .tokenomics-table {
        top: 13%;

        table {
          @apply mx-auto w-2/3;

          td.cell-label {
            @apply text-brown2 text-6.5  leading-12 text-left;
          }
          td.cell-value {
            @apply text-white text-right text-6  leading-12;
          }

          tr.total td {
            @apply underline;
          }
        }
      }
    }
  }

  #nft {
    .nft-content-wrap {
      bottom: 13%;
      width: 84%;
      margin-left: -40%;
      p.nft-content {
        @apply sm:text-4 sm:leading-6.75;
      }
    }
  }

  #crypto-holder {
    height: 1200px;

    .crypto-holder-content-wrap {
      p.crypto-holder-content1,
      p.crypto-holder-content2 {
        @apply max-w-md;
        @apply sm:text-5 sm:leading-7;
      }
    }
  }

  #join {
    .join-link {
      top: 42%;
      left: 30%;
      width: 60%;
      height: 20%;
    }
  }
}
