@media only screen and (min-width: 768px) {
  #home-top {
    &.top-anim {
      .img1 {
        margin-top: 92px;

        transition: margin-top 1s linear;
        -webkit-animation: margin-top 1s linear;
      }
      .img3 {
        margin-top: 132px;

        transition: margin-top 1s linear;
        -webkit-animation: margin-top 1s linear;
      }

      .img4 {
        margin-top: 149px;

        transition: margin-top 1s linear;
        -webkit-animation: margin-top 1s linear;
      }
    }
  }

  #crypto-court2-horse-wrap img {
    margin-left: -700px;
  }

  #token.token-anim1 {
    #crypto-court2-horse-wrap img {
      margin-left: calc(100% + 700px);

      transition: margin-left 6s linear;
      -webkit-animation: margin-left 6s linear;
    }
  }

  #tax {
    #tax2 {
      .gun.gun-anim {
        right: 120px;

        transition: right 1s linear;
        -webkit-animation: right 1s linear;
      }

      .tax2-content {
        h1 {
          margin-left: 150px;
        }

        p {
          margin-left: -20px;
          margin-right: 20px;
        }

        &.tax2-anim {
          h1 {
            margin-left: 0px;

            transition: margin-left 0.5s linear;
            -webkit-animation: margin-left 0.5s linear;
          }
          p {
            margin-left: 0;
            margin-right: 0;

            transition: all 0.8s linear;
            -webkit-animation: all 0.8s linear;
          }
        }
      }
    }

    .house-wrap {
      margin-left: -200px;

      &.house-anim {
        margin-left: 0px;

        transition: margin-left 1s linear;
        -webkit-animation: margin-left 1s linear;
      }
    }
  }

  #tokenomics {
    .inner {
      @apply hidden;
    }

    .bomb {
      @apply absolute hidden;
      top: -425px;
      right: -320px;
      width: 1331px;
      height: 951px;

      @media screen and (min-width: 1440px) {
        top: -340px;
        right: -256px;
        width: 1065px;
        height: 761px;
      }
    }

    &.tokenomics-anim {
      .bomb {
        @apply block;

        transition: all 2s;
        -webkit-animation: all 2s;
        top: 0;
        right: 0;
      }
    }

    &.tokenomics-anim2 {
      .inner {
        @apply flex;

        transition: all 2s;
        -webkit-animation: all 2s;
      }

      .bomb {
        @apply hidden;
      }
    }
  }
}
