@media only screen and (min-width: 769px) {
  body {
    min-width: 1440px;
    overflow-x: auto;
    background-color: transparent;
  }

  .page .container {
    padding: 0 9.5% 0 9.5%;
  }

  .banner {
    width: 100%;
    padding-bottom: 0px;

    background: url('../../assets/images/judge-desktop/banner/banner-bg.png') 0 0 no-repeat;
    background-color: #121212;
    background-size: cover;

    .banner-logo {
      margin-top: 50px;
      width: 592px;
      height: 262px;
    }
    .banner-man {
      width: 522px;
      height: 590px;
    }

    .join-waitlist {
      @apply absolute inline-block;

      position: absolute;
      display: inline-block;
      bottom: 43px;
      right: 36px;
      width: 278px;
      height: 106px;
      text-indent: -9999px;
    }
  }

  .html-embed {
    min-height: 100vh;
  }

  .background__video {
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 100vh;
    pointer-events: none;
  }

  #home-top {
    @apply relative;
    padding-top: 66px;
    padding-bottom: 264px;
    background-color: #121212;
    background-image: url ('../../assets/images/judge-desktop/home-top-bg-gradient.png') 0 0 no-repeat;

    .images-wrap {
      padding: 0 135px 0 135px;
      margin: 0 -30px 0 -30px;
      & > img,
      & > div {
        padding: 0 30px;
        margin-top: 0;
      }
    }

    h1 {
      @apply font-bon text-22.5 leading-12;
      @apply mt-20;
      letter-spacing: 0.33em;
      --stroke-color: #7a6951;
      --stroke-width: 4px;

      text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
        calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
        calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
        calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color);
    }
    p {
      @apply font-bogart text-6.25 leading-7.5 text-center;
    }
  }

  #crypto-court {
    min-height: 809px;
    h1 {
      @apply text-15 leading-12.5;
      letter-spacing: 0.06em;

      --stroke-color: rgba(33, 28, 25, 1);
      --stroke-width: 4px;
    }

    p {
      @apply leading-4 text-center;
    }

    .crypto-court-content {
      @apply ml-0;
      top: 25%;
      left: 6%;
      width: 55%;

      .crypto-court-content-inner {
        @apply absolute px-28 top-0 pt-8;

        h1 {
          @apply my-8;
        }

        p {
          @apply text-6.25 leading-7.5 text-white text-center font-bogart;
        }
      }
    }
  }

  .crypto-court-bottom {
    @apply relative;
    margin-top: -30px;
  }

  #crypto-court2-horse-wrap {
    #crypto-court2-horse {
      width: 934px;
      height: 448px;
    }
  }

  #token {
    @apply relative text-center font-bon;

    .container2 {
      @apply mx-auto;
      max-width: 1000px;
    }

    margin-top: -30px;

    background: url('../../assets/images/judge-desktop/crypto-court2-bg.png') 0 0 no-repeat;
    background-size: cover;
    background-clip: content-box;
    min-height: 769px;

    &.preloading-bg {
      background: url('../../assets/images/judge-desktop/token-bg.png') 0 0 no-repeat;
      display: none;
    }

    #solana-logo {
      width: 1095px;
      height: initial;
      @apply mx-auto;
      padding-top: 240px;
      opacity: 0;
    }

    &.token-anim1 {
      #solana-logo {
        @apply block;
        padding-top: 100px;
        opacity: 1;

        -webkit-animation: padding-top 5s linear;
        transition: padding-top 5s linear;
      }
    }

    .token-content-wrap {
      margin: 27%;
    }
    &.token-anim2 {
      background: url('../../assets/images/judge-desktop/token-bg.png') 0 0 no-repeat;
      background-size: cover;
      -webkit-animation: all 1s ease-in-out;
      transition: all 1s ease-in-out;
      height: 1608px;

      .token-content-wrap {
        @apply block;
        margin: 0 auto;
        -webkit-animation: all 1s ease-in-out;
        transition: all 1s ease-in-out;
      }
    }

    h1.token-title {
      @apply text-15 leading-17.5 mt-13 mb-15;
      max-width: 50%;
    }

    h3.token-subtitle {
      @apply text-9 leading-12 mt-8;
    }

    p.token-content {
      @apply text-5.75 leading-8;
      letter-spacing: 0em;
    }

    p.note {
      @apply text-5 leading-7.5 font-bogart text-brown2 py-8;
    }

    ul.voting-list {
      li {
        @apply text-5.75 leading-9;
        &:last-child {
          @apply mt-0;
        }
      }
    }

    .token-img1,
    .token-img2 {
      @apply absolute bottom-2;
    }

    .token-img1 {
      width: 242px;
      height: 484px;
    }
    .token-img2 {
      width: 208px;
      height: 486px;
    }
  }

  #token-bottom {
    @apply relative z-10;
    margin-top: -160px;
  }

  #token-bottom2 {
    @apply relative z-20;
    margin-top: -69px;
  }

  #tax {
    background: url('../../assets/images/judge-desktop/tax/tax-bg.png') rgba(0, 0, 0, 0.89) 0 0 no-repeat;
    background-size: cover;

    margin-top: -225px;
    padding-bottom: 187px;
    padding-left: 7%;

    h1 {
      @apply text-left font-bon;
      @apply text-15 leading-17.5;
      letter-spacing: 0.1em;
    }

    p {
      @apply text-left font-bogart text-white;
      @apply text-5.75 leading-8;
    }

    #tax1 {
      padding-top: 225px;

      .tax1-img {
        width: 70%;
        min-height: 693px;
      }

      .tax1-star1 {
        @apply -left-12 -top-5;
      }

      .tax1-star-single {
        width: 71px;
        height: 101px;
        margin-top: 160px;
        margin-left: 150px;
      }

      .tax1-star2 {
        margin-top: 330px;
        margin-left: 180px;
      }

      .tax1-pistol {
        width: 18%;
        min-height: 178px;
        left: 64px;
        top: 112px;
      }

      .tax1-content {
        top: 224px;
        left: 210px;
        max-width: 42%;

        h1 {
          margin-bottom: 40px;
        }
      }
    }

    #tax2 {
      padding-top: 80px;

      .house {
        width: 436px;
        min-height: 436px;
      }

      .tax2-img {
        width: 850px;
        height: 684px;
      }

      .gun {
        width: 35%;
        height: 216px;
        top: -67px;
        right: -200px;
      }

      .tax2-content {
        width: 80%;
        left: 53%;
        margin-top: 40px;
        margin-left: -40%;
        h1 {
          margin-bottom: 32px;
          letter-spacing: -0.02em;
        }
      }
    }

    .wanted-bottom {
      @apply z-10;
      bottom: -140px;
      left: 80px;
      width: 332px;
      height: 524px;
    }
  }

  #tokenomics {
    @apply relative text-center font-bon;
    min-height: 842px;

    background: url('../../assets/images/judge-desktop/tokenomics/tokenomics-bg.png') rgba(18, 18, 18, 1) 0 0 no-repeat;
    background-size: cover;
    background-clip: content-box;

    .inner {
      padding: 100px 5.8% 100px;
    }

    .tokenomics-left {
      @apply text-left pt-32;

      h1 {
        @apply text-14.25 leading-17.5 mb-8 text-white;
        letter-spacing: 0.15em;

        --stroke-color2: #615442;
        --stroke-width: 4px;

        text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color2),
          calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color2),
          calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color2);
      }
      p {
        @apply font-bogart text-5.75 leading-8;
      }
    }

    .tokenomics-right {
      @apply ml-10;

      .tokenomics-table {
        @apply w-full absolute top-0;
        max-width: 843px;
        padding: 80px 40px;

        p {
          @apply font-bogart text-5.75 leading-8 text-center mx-auto;
          max-width: 663px;
        }

        table {
          @apply w-full;
          margin-top: 32px;
        }

        td.cell-label {
          @apply text-brown2 text-left leading-8.5;
          font-size: 33px;
        }

        td.cell-value {
          @apply text-white leading-8.5;
          font-size: 25px;
          line-height: 40px;
        }

        tr.total td {
          @apply underline;
        }
      }
    }
  }

  #official {
    @apply relative text-center font-bon;

    height: unset;

    background: url('../../assets/images/judge-desktop/official-bg.png') 0 0 no-repeat;
    background-size: cover;
    background-clip: content-box;
    min-height: 831px;

    .official-inner {
      padding: 40px 80px 40px 80px;

      h1.official-title {
        @apply text-brown2;
        font-size: 90px;
        line-height: 50px;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: rgba(97, 84, 66, 1);
      }

      .official-sep {
        @apply mx-auto;
        width: 60%;
      }
    }
  }

  #nft {
    @apply relative text-center font-bon;

    /* background: url('../../assets/images/judge-desktop/nft-bg.png') 0 0 no-repeat; */
    background: url('../../assets/images/judge-desktop/nft-bg-final.png') 0 0 no-repeat;
    background-size: cover;
    background-clip: content-box;
    min-height: 717px;

    &:before {
      content: '';
      @apply w-full absolute left-0 bottom-0;
      height: 50%;
      background: linear-gradient(0deg, #121212 0%, rgba(18, 18, 18, 0) 100%);
    }

    .nft-content-wrap {
      @apply absolute;
      bottom: 20px;
      width: 1139px;
      height: 353px;
      left: 50%;
      margin-left: -568px;
      padding: 70px 80px;

      background: url('../../assets/images/judge-desktop/nft-board-bg.png') 0 0 no-repeat;
      background-size: auto;

      h1 {
        @apply text-white;
        font-size: 62px;
        line-height: 50px;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: rgba(75, 42, 22, 1);
      }

      p.nft-content {
        @apply font-bogart mx-auto text-5.5 leading-7.5;
        color: rgba(18, 18, 18, 1);
        letter-spacing: -0.05em;
      }
    }
  }

  #join {
    background: url('../../assets/images/judge-desktop/join-bg.png') #000 0 0 no-repeat;
    background-size: cover;
    min-height: 678px;

    .join-link {
      @apply absolute inline-block;
      background: url('../../assets/images/judge-desktop/join-link.png') 0 0 no-repeat;
      background-size: contain;

      top: 42%;
      left: 30%;
      width: 61%;
      height: 203px;
      text-indent: -9999px;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}
