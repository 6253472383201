.footer {
  @apply flex flex-col justify-center;

  height: 266px;

  .logo-wrap {
    @apply inline-block mx-auto;

    img {
      /* background: url('../../assets/images/judge/judgement-logo.png') 0 0 no-repeat; */
      width: 243px;
      height: 107px;
    }
  }

  .nav {
    text-transform: uppercase;

    hover {
      opacity: 0.8;
      transition: all;
      transition-duration: 300ms;
    }

    --stroke-color: #492a24;
    --stroke-width: 1px;

    text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0 var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0 var(--stroke-color);
  }

  .social-link {
    @apply cursor-pointer mx-2 mt-8;
    cursor: pointer;
    text-indent: -6000px;
    width: 30px;
    height: 23px;
    background-size: contain;
    background-repeat: no-repeat;

    &.x {
      background-image: url('../../assets/images/judge/x.png');
    }

    &.discord {
      background-image: url('../../assets/images/judge/discord.png');
    }

    &.note {
      @apply ml-0;
      background-image: url('../../assets/images/judge/book.png');
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    background-color: #121212;
  }
}

@media only screen and (min-width: 769px) {
  .footer {
    height: 323px;

    .social-link {
      @apply mx-3 mt-8;
      scale: 1.25;
    }

    .logo-wrap {
      img {
        width: 500px;
        height: 221px;
      }
    }

    .nav {
      @apply text-5 leading-7.5 font-bon block;
      padding-left: 120px;
      color: rgba(245, 237, 226, 1);
    }
  }
}

@media only screen and (min-width: 1792px) {
  .footer {
    height: 323px;

    .social-link {
      @apply mx-3 mt-8;
      scale: 1.25;
    }

    .logo-wrap {
      img {
        width: 500px;
        height: 221px;
      }
    }

    .nav {
      @apply text-5 leading-7.5 font-bon block;
      padding-left: 120px;
      color: rgba(245, 237, 226, 1);
    }
  }
}
