.carousel-multi {
  .embla {
    position: relative;
    max-width: 100%;
    margin-right: auto;
  }

  .embla__viewport {
    overflow: hidden;
    width: 100%;
  }

  .embla__viewport.is-draggable {
    cursor: move;
    cursor: grab;
  }

  .embla__viewport.is-dragging {
    cursor: grabbing;
  }

  .embla__container {
    display: flex;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -webkit-tap-highlight-color: transparent;
    @apply ml-0;
    height: 323px;
  }

  .embla__slide {
    @apply relative mx-2;

    flex: 0 0 175px;
  }

  .embla__slide__inner {
    position: relative;
    overflow: hidden;
  }

  .embla__button {
    outline: 0;
    cursor: pointer;
    background-color: transparent;
    touch-action: manipulation;
    position: absolute;
    z-index: 1;
    top: 140px;
    transform: translateY(-50%);
    border: 0;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    padding: 0;

    &.embla__button--prev {
      margin-top: -10px;
    }
  }

  .embla__button:disabled {
    cursor: default;
    opacity: 0;
  }

  .embla__button__svg {
    width: 100%;
    height: 100%;
  }

  .embla__button--prev {
    left: -3rem;
    /* @apply left-[-3rem] xl:left-[-4rem] 4xl:-left-24; */
    transform: rotate(180deg);
  }

  .embla__button--next {
    right: -3rem;
    /* @apply right-[-3rem] xl:right-[-4rem] 4xl:-right-24; */
  }
}

@media screen and (min-width: 1440px) {
  .carousel-multi {
    @apply ml-10;
    .embla {
      position: relative;
      max-width: 100%;
    }

    .embla__container {
      height: 540px;
      @apply ml-0;
    }

    .embla__slide {
      @apply relative;
      margin: 0 24px;

      flex: 0 0 272px;

      .member-desc {
        @apply hidden absolute text-3.75 leading-4.5 text-white text-left font-bogart;
        background: url('../../assets/images/judge-desktop/member-bg.png') 0 0 no-repeat;
        background-size: cover;
        opacity: 0.2;
        padding: 22px 16px;
        transition: all 1s ease-out;
        -webkit-transition: all 1s ease-out;
      }

      transition: all 1s;
      -webkit-transition: all 1s;

      &:hover {
        margin-top: 20px;
        transition: all 1s;
        -webkit-transition: all 1s;

        .member-desc {
          @apply flex items-end;
          opacity: 1;
          transition: all 1s ease-out;
          -webkit-transition: all 1s ease-out;
        }
      }
    }

    .embla__button {
      outline: 0;
      cursor: pointer;
      background-color: transparent;
      touch-action: manipulation;
      position: absolute;
      z-index: 1;
      top: 140px;
      transform: translateY(-50%);
      border: 0;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      padding: 0;

      &.embla__button--prev {
        margin-top: -10px;
      }
    }

    .embla__button__svg {
      width: 100%;
      height: 100%;
    }

    .embla__button--prev {
      left: -3rem;
      transform: rotate(180deg);
    }

    .embla__button--next {
      right: -3rem;
    }
  }
}

@media screen and (min-width: 1792px) {
  .carousel-multi {
    @apply ml-10;
    .embla {
      position: relative;
      max-width: 100%;
    }

    .embla__container {
      height: 540px;
      @apply ml-0;
    }

    .embla__slide {
      @apply relative;
      margin: 0 24px;

      flex: 0 0 272px;

      .member-desc {
        @apply hidden absolute text-3.75 leading-4.5 text-white text-left font-bogart;
        background: url('../../assets/images/judge-desktop/member-bg.png') 0 0 no-repeat;
        background-size: cover;
        opacity: 0.2;
        padding: 22px 16px;
        transition: all 1s ease-out;
        -webkit-transition: all 1s ease-out;
      }

      transition: all 1s;
      -webkit-transition: all 1s;

      &:hover {
        margin-top: 20px;
        transition: all 1s;
        -webkit-transition: all 1s;

        .member-desc {
          @apply flex items-end;
          opacity: 1;
          transition: all 1s ease-out;
          -webkit-transition: all 1s ease-out;
        }
      }
    }

    .embla__button {
      outline: 0;
      cursor: pointer;
      background-color: transparent;
      touch-action: manipulation;
      position: absolute;
      z-index: 1;
      top: 140px;
      transform: translateY(-50%);
      border: 0;
      width: 44px;
      height: 44px;
      justify-content: center;
      align-items: center;
      padding: 0;

      &.embla__button--prev {
        margin-top: -10px;
      }
    }

    .embla__button__svg {
      width: 100%;
      height: 100%;
    }

    .embla__button--prev {
      left: -3rem;
      transform: rotate(180deg);
    }

    .embla__button--next {
      right: -3rem;
    }
  }
}
